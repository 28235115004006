.users {
    margin-top: 100px;
}

.user-table {
    margin: 0px 20px 0px 20px
}

.user-button-container {
    display: flex;
    justify-content: flex-end;
    /* Pomeranje dugmeta ulevo */
    align-items: flex-end;
    /* Pomeranje dugmeta dole */

    /* Postavi visinu kako bi dugme bilo na dnu */
    margin-top: 20px;
    /* Ako je u flex kontejneru, ovo će ga pomeriti dole */
}